<template>
  <div>
    <div class="login__box">
      <div class="login__box-container">
        <div class="login-head">
          <label @click="$router.push('/my')" style="display: flex;align-items: center;cursor: pointer;">
            <div class="game-detail-back">
              <img src="../../../static/icon/back.png"/>
            </div>
            <div style="font-weight: 700;font-size: 20px;">登录</div>
          </label>
        </div>
        <div class="login-body">
          <div class="login-body-item">
            <div class="login-body-item-input-view">
              <input v-model="loginParam.username" class="login-body-item-input" type="text" placeholder="请输入手机号" />
            </div>
            <div class="login-body-item-input-view">
              <input @keyup.enter="doLogin" v-model="loginParam.password" class="login-body-item-input" type="password" placeholder="请输入密码" />
            </div>
            <div class="login-body-item-input-view">
              <div style="float: right;user-select: none;font-size: 14px;color: #9ad1aa" @click="toRegister">立即注册</div>
            </div>
          </div>
          <div class="login-body-item">
            <div>
              <van-checkbox style="width: 100%;"
                            v-model="consent"
                            checked-color="#9ad1aa"
                            icon-size="16px">
                <div style="font-size: 14px;color: #b8bec4;">
                    我已阅读并同意
                  <span @click="$router.push('/user/agreement')" style="color: #0c5ab0">《用户协议》</span>
                  &
                  <span @click="$router.push('/privacy')" style="color: #0c5ab0">《隐私协议》</span>
                </div>
              </van-checkbox>
            </div>
            <div>
              <van-button @click="doLogin" round block color="#b3d9b5">登录</van-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tools from "@/utils/Tools";
import Crypto from "@/utils/crypto";
import {login} from "@/api/request";

export default {
  name: "login",
  data(){
    return {
      consent: false,
      loginParam: {
        username: '',
        password: ''
      },
    }
  },
  methods: {
    doLogin(){
      if(!Tools.isPhone(this.loginParam.username)){
        this.$toast.fail('手机号码无效，请正确填写手机号码')
      }else if(!Tools.isPassWord(this.loginParam.password)){
        this.$toast.fail("密码为6-12位数字、字母、字符")
      }else if(!this.consent){
        this.$toast.fail("为了更好地保障你的合法权益，进入下一步前，请阅读并同意 《用户协议》&《隐私政策》")
      }else {
        let params = {
          username: this.loginParam.username,
          password: Crypto.encrypt(this.loginParam.password)
        }
        login(params).then(res => {
          if(res.data.success){
            localStorage.setItem("web_token",res.data.data.token);
            localStorage.setItem("user_info",JSON.stringify(res.data.data));
            this.loginParam = {
              username: '',
              password: ''
            }
            this.$router.replace('/my')
          }else {
            this.$toast.fail(res.data.error.message);
          }
        })
      }
    },
    toRegister(){
      this.$router.push('register');
    },
  }
}
</script>

<style scoped>
.login__box {
  display: flex;
  align-items: center;
  min-height: 100vh;
  -webkit-box-align: center;
}
.login__box-container {
  width: 500px;
  height: 700px;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  margin: 0 auto;
}
.login-head {
  height: 56px;
  padding: 0 10px;
  
  display: flex;
  align-items: center;
}
.login-body {
  display: flex;
  flex-direction: column;
}
.login-body-item {
  /*50vh - (login-head 高度 / 2)*/
  height: calc(50vh - (56px / 2));
  padding: 0 20px;
  
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}
.login-body-item-input-view {
  /*margin-top: 10px;*/
}
.login-body-item-input {
  width: calc(100% - 20px);
  border: 0;
  background-color: transparent;
  font-size: 18px;
  font-weight: 600;
  padding: 15px 10px;

  border-bottom: 1px solid #e1e1e1;
  transition: border-bottom-color .2s ease-in-out;
}
.login-body-item-input:focus {
  border-bottom-color: #b3d9b5;
}

.game-detail-back {
  margin-right: 5px;
  cursor: pointer;
  width: 30px;
  height: 30px;
}
.game-detail-back img {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 600px){
  .login__box-container {
    width: calc(100% - 2px);
    height: calc(100vh - 2px);
    /*border: 0;*/
    margin: 0;
    /*overflow: hidden;*/
  }
}
</style>