<template>
    <div class="container">
        <div class="container_main">
            <div class="container_main_inner">
                <div style="margin-bottom: 40px;display: flex;flex-direction: column;justify-content: left;">
                    <div style="display: flex;flex-direction: row;align-items: center;">
                        <img :src="logo" style="width:100px;height: 100px;transform: translateX(-20px);"/>
                        <div class="web_name">{{ webname }}</div>
                    </div>
                    <div class="account_tips" style="margin-top: 10px;">使用{{ webname }}账号登录，绑定微信</div>
                    <div class="account_tips">{{ webname }}账号绑定的微信会直接收到红包</div>
                    <div class="account_tips">更多福利请<a :href="websiteLink" target="_blank">点击跳转</a></div>
                </div>
                <div class="form-control">
                    <input v-model="form.userName" type="text" required="">
                    <label>
                        <span style="transition-delay:0ms">账号</span>
                    </label>
                </div>
                <div class="form-control">
                    <input v-model="form.password" type="password" required="">
                    <label>
                        <span style="transition-delay:0ms">密码</span>
                    </label>
                </div>
                <div :class="{'login_container': loading}">
                    <div class="login_btn" :class="{'login_btn_disable': loading}" @click="submit">
                        确认绑定
                    </div>
                </div>
            </div>
        </div>
        <van-overlay :show="loading">
            <div style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center">
                <div style="width: 130px;height: 130px;border-radius: 5px; background-color: #333;display: flex;align-items: center;justify-content: center">
                    <van-loading type="spinner" vertical color="#d5d5d5" size="36px" style="user-select: none">
                        绑定中...
                    </van-loading>
                </div>
            </div>
        </van-overlay>
    </div>
</template>

<script>
import { Dialog } from 'vant';
import logo from "@/assets/sw_logo_128.png";
import {request} from "@/api/request";
import crypto from "@/utils/crypto";
export default {
    name: "bindwx",
    data() {
        return {
            logo,
            appid: 'wx4684b3ea928bef8e',
            code: '',
            form: {
                userName: '',
                password: ''
            },
            loading: false,
            websiteLink: 'https://game.sw007.cn/home',
            webname: '爽玩游戏'
        }
    },
    mounted() {
        let code = this.getUrlKey("code");
        if (!code) {
            this.getCodeApi();
        }
        this.code = code;
    },
    methods: {
        getUrlKey(name) {//获取url 参数
            return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null;
        },
        getCodeApi() {//获取code   
            let urlNow = encodeURIComponent(window.location.href);
            // let scope='snsapi_userinfo';    //snsapi_userinfo snsapi_base   //静默授权 用户无感知
            let url = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + this.appid + '&redirect_uri=' + urlNow + '&response_type=code&scope=snsapi_userinfo#wechat_redirect';
            window.location.href = url
        },
        submit() {
            this.loading = true;
            if (this.form.userName === '' || this.form.password === '') {
                Dialog.alert({
                    message: '账号和密码不能为空',
                }).then(() => {
                });
                return;
            }
            let options = {
                url: '/h5/accountBindWx',
                method: 'POST',
                data: {
                    userName: this.form.userName,
                    password: crypto.encrypt(this.form.password),
                    code: this.code
                }
            }
            request(options).then(res => {
                if (res.data.success) {
                    Dialog.alert({
                        message: '绑定成功',
                    }).then(() => {

                    });
                } else {
                    Dialog.alert({
                        message: res.data.error.message,
                    }).then(() => {
                    });
                }
            }).finally(() => {
                this.loading = false;
            });
        },
    },


}
</script>

<style>
:root {
    --normal-color: #90e1ac;
    --active-color: #73b28a;
    --hover-color: rgba(144, 225, 172, 0.8);
}
</style>
<style scoped>
.container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.container_main {
    width: 100%;
    height: 100%;
    max-width: 600px;
    max-height: 700px;
    min-width: 375px;
    min-height: 667px;
    border: 1px solid #d5d5d5;
    border-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container_main_inner {
    padding: 40px 15%;
    flex: 1;
}

.web_name {
    font-size: 36px;
    font-weight: bold;
    color: #252525;
}

.account_tips {
    color: #888888;
    text-align: left;
    font-size: 14px;
    user-select: none;
}

.account_tips a:link, a:visited, a:hover, a:active{
    color: var(--normal-color);
}

.login_btn {
    width: 100%;
    height: 40px;
    line-height: 40px;
    background-color: var(--normal-color);
    border-radius: 99px;
    color: #fff;
    margin-top: 80px;
    user-select: none;
    cursor:pointer;
    text-align: center;
}

.login_btn:active {
    background-color: var(--active-color);
}
.login_btn:hover {
    background-color: var(--hover-color);
}
.login_container {
    cursor: not-allowed;
}
.login_btn_disable {
    pointer-events: none;
    background-color: var(--hover-color);
}

.form-control {
    position: relative;
    margin: 20px 0 40px;
    width: 100%;
    user-select: none;
}

.form-control input {
    background-color: transparent;
    border: 0;
    border-bottom: 2px #3a3a3a solid;
    display: block;
    width: 100%;
    padding: 15px 0;
    font-size: 18px;
    color: #3a3a3a;
}

.form-control input:focus,
.form-control input:valid {
    outline: 0;
    border-bottom-color: var(--normal-color);
}

.form-control label {
    position: absolute;
    top: 15px;
    left: 0;
    pointer-events: none;
}

.form-control label span {
    display: inline-block;
    font-size: 18px;
    min-width: 5px;
    color: #3a3a3a;
    transition: 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.form-control input:focus+label span,
.form-control input:valid+label span {
    color: var(--normal-color);
    transform: translateY(-30px);
}

@media only screen and (max-width: 600px) {
    .container_main {
        border: 0;
    }
}
</style>