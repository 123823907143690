import VueRouter from "vue-router";
import Vue from "vue";
/***********************layout*****************************/
import IndexTwo from "@/page/layout/index.vue";
import LayoutHome from "@/page/layout/home/home.vue";
import LayoutTop from "@/page/layout/top/top.vue";
import LayoutMy from "@/page/layout/my/my.vue";
import LayoutGameDetails from "@/page/layout/game/gameDetails.vue";
import LayoutSetting from "@/page/layout/setting/setting.vue";
import LayoutLogin from "@/page/layout/login/login.vue";
import LayoutRegister from "@/page/layout/login/register.vue";
import LayoutIntroduce from "@/page/layout/setting/about/aboutInfo.vue";
import LayoutAbout from "@/page/layout/setting/about/about.vue";
import LayoutPrivacy from "@/page/layout/setting/about/privacy.vue";
import LayoutUserAgreement from "@/page/layout/setting/about/userAgreement.vue";
import LayoutSecurityPage from "@/page/layout/setting/security/securityPage.vue";
import LayoutRealNameAuth from "@/page/layout/setting/security/realNameAuth.vue";
import LayoutModifyPassword from "@/page/layout/setting/security/modifyPassword.vue";
import LayoutGameSearch from "@/page/layout/game/search.vue";
import LayoutGift from "@/page/layout/gift/giftlist.vue";
import LayoutContent from "@/page/layout/content/contentlist.vue";
import LayoutContentDetail from "@/page/layout/content/contentDetail.vue";
import DownloadPage from "@/page/layout/game/downloadPage.vue";
import SdkMain from "@/page/sdk/SdkMain.vue";
import SdkPassword from "@/page/sdk/SdkPassword.vue";
import SdkKf from "@/page/sdk/SdkKf.vue";
import SdkAccount from "@/page/sdk/SdkAccount.vue";
import SdkRecharge from "@/page/sdk/SdkRecharge.vue";
import Main from '@/page/sdk/Main.vue';
import mainMenu from "@/components/MainMenu.vue";
import SdkWeixin from "@/page/sdk/SdkWeixin.vue";
import SdkGift from "@/page/sdk/SdkGift.vue";
import SdkGiftRecord from "@/page/sdk/SdkGiftRecord.vue";
import GameMain from "@/page/game/GameMain.vue";
import GameRegister from "@/page/game/GameRegister.vue";
import GameReset from "@/page/game/GameReset.vue";
import LayoutHotGame from "@/page/layout/home/hotGame.vue";
import WxPayQrCode from "@/page/webview/WxPayQrCode.vue";
import WeixinJSPay from "@/page/open/WeixinJSPay.vue";
import BindWeixin from "@/page/open/BindWeixin";


import H5GameTest from "@/page/game/H5GameTest.vue";
import LegendaryBinding from "@/page/open/LegendaryBinding.vue";

Vue.use(VueRouter);

const titles = [
    {
        title: '爽玩网络'
    },
]
const title = titles[0].title;

export default new VueRouter({
    base: process.env.BASE_URL,
    scrollBehavior(to, from, savedPosition) {
        return {
            x: 0,
            y: 0
        }
    },
    mode: 'history',
    routes: [
        {
            path: '/',
            redirect: '/home'
        },
        {
            path: '/h5/:gameId',
            name: 'GameMain',
            component: GameMain
        },
        {
            path: '/h5/:gameId/:channelId',
            name: 'GameMain',
            component: GameMain,
        },
        {
            path: '/h5game/register',
            name: 'GameRegister',
            component: GameRegister,
        },
        {
            path: '/h5game/reset',
            name: 'GameReset',
            component: GameReset,
        },
        {
            path: '/h5GameTest',
            name: 'H5GameTest',
            component: H5GameTest,
        },


        {
            path: '/index',
            name: 'Index',
            component: IndexTwo,
            children: [
                {
                    path: '/home',
                    name: "Home",
                    component: LayoutHome,
                    meta: {
                        showNavigation: true,
                        title: title
                    }
                },
                {
                    path: '/hot',
                    name: "HOTGame",
                    component: LayoutHotGame,
                    meta: {
                        showNavigation: true,
                        title: title
                    }
                },
                {
                    path: '/top',
                    name: "Top",
                    component: LayoutTop,
                    meta: {
                        showNavigation: true,
                        title: title
                    }
                },
                {
                    path: '/my',
                    name: 'My',
                    component: LayoutMy,
                    meta: {
                        showNavigation: true,
                        title: title
                    }
                },
                {
                    path: '/game/details/*',
                    name: "GameDetails",
                    component: LayoutGameDetails,
                    meta: {
                        showNavigation: false,
                        title: title
                    }
                },
                {
                    path: '/game/gift/*',
                    name: "GiftList",
                    component: LayoutGift,
                    meta: {
                        showNavigation: false,
                        title: title
                    }
                },
                {
                    path: '/content/*',
                    name: "ContentList",
                    component: LayoutContent,
                    meta: {
                        showNavigation: false,
                        title: title
                    }
                },
                {
                    path: '/game/content/detail/*',
                    name: "ContentDetail",
                    component: LayoutContentDetail,
                    meta: {
                        showNavigation: false,
                        title: title
                    }
                },
                {
                    path: '/setting',
                    name: "Setting",
                    component: LayoutSetting,
                    meta: {
                        showNavigation: false,
                        title: title
                    }
                },
                {
                    path: '/introduce',
                    name: "Introduce",
                    component: LayoutIntroduce,
                    meta: {
                        showNavigation: false,
                        title: title
                    }
                },
                {
                    path: '/about',
                    name: "About",
                    component: LayoutAbout,
                    meta: {
                        showNavigation: false,
                        title: title
                    }
                },
                {
                    path: '/privacy',
                    name: "Privacy",
                    component: LayoutPrivacy,
                    meta: {
                        showNavigation: false,
                        title: title
                    }
                },
                {
                    path: '/user/agreement',
                    name: "UserAgreement",
                    component: LayoutUserAgreement,
                    meta: {
                        showNavigation: false,
                        title: title
                    }
                },
                {
                    path: '/security/page',
                    name: "SecurityPage",
                    component: LayoutSecurityPage,
                    meta: {
                        showNavigation: false,
                        title: title
                    }
                },
                {
                    path: '/realName',
                    name: "realNameAuth",
                    component: LayoutRealNameAuth,
                    meta: {
                        showNavigation: false,
                        title: title
                    }
                },
                {
                    path: '/modify/password',
                    name: "ModifyPassword",
                    component: LayoutModifyPassword,
                    meta: {
                        showNavigation: false,
                        title: title
                    }
                },
                {
                    path: '/game/search/*',
                    name: "GameSearch",
                    component: LayoutGameSearch,
                    meta: {
                        showNavigation: false,
                        title: title
                    }
                },
            ]
        },
        // {
        //   path: "/login",
        //   name: "Login",
        //   component: LayoutLogin,
        //   meta: {
        //     showNavigation: false,
        //     title: title
        //   }
        // },
        // {
        //   path: "/register",
        //   name: "Register",
        //   component: LayoutRegister,
        //   meta: {
        //     showNavigation: false,
        //     title: title
        //   }
        // },
        {
            path: "/download",
            name: "Download",
            component: DownloadPage,
            meta: {
                showNavigation: false,
                title: title
            }
        },
        // {
        //   path: '/h5game/*',
        //   name: 'GameMain',
        //   component: GameMain
        // },
        {
            path: '/sdk/main',
            name: 'SdkMain',
            component: Main
        },
        {
            path: '/sdk/password',
            name: 'SdkPassword',
            component: SdkPassword
        },
        {
            path: '/sdk/kf',
            name: 'SdkKf',
            component: SdkKf
        },
        {
            path: '/sdk/account',
            name: 'SdkAccount',
            component: SdkAccount
        },
        {
            path: '/sdk/recharge',
            name: 'SdkRecharge',
            component: SdkRecharge
        },
        {
            path: '/sdk/weixin',
            name: 'SdkWX',
            component: SdkWeixin
        },
        {
            path: '/sdk/gift',
            name: 'SdkGift',
            component: SdkGift
        },
        {
            path: '/sdk/giftRecord',
            name: 'SdkGiftRecord',
            component: SdkGiftRecord
        },
        {
            path: '/wxPayQrCode',
            name: 'WxPayQrCode',
            component: WxPayQrCode
        },
        {
            path: '/weixinJSPay',
            name: 'WeixinJSPay',
            component: WeixinJSPay
        },
        {
            path: '/bindWeixin',
            name: 'BindWeixin',
            component: BindWeixin,
            meta: {
                title: "微信绑定"
            }
        },
        {
            path: '/legBind',
            name: 'LegendaryBinding',
            component: LegendaryBinding,
            meta: {
                title: title
            }
        }

    ]
})

/*重复路由报错*/
const RouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
    return RouterPush.call(this, to).catch(err => err)
}
const RouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(to) {
    return RouterReplace.call(this, to).catch(err => err)
}