import { render, staticRenderFns } from "./LegendaryBinding.vue?vue&type=template&id=5c5d9e20&scoped=true"
import script from "./LegendaryBinding.vue?vue&type=script&lang=js"
export * from "./LegendaryBinding.vue?vue&type=script&lang=js"
import style0 from "./LegendaryBinding.vue?vue&type=style&index=0&id=5c5d9e20&prod&lang=css"
import style1 from "./LegendaryBinding.vue?vue&type=style&index=1&id=5c5d9e20&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c5d9e20",
  null
  
)

export default component.exports